
export class Config {

    static organizationId = '5e8ba9e576f63868a650f714';
    static organizationName = " You Chef You";
    static appVersion = '2.31.00';

    static cipher: string = 'KPdzpXFGaUkAFHwj5FBy82B6'; 

    static isCanuucan = 'false';

    static pickupTypes = [ 'DELIVERYTYPE02', 'DELIVERYTYPE07' ];
    static zendeskAccountUrl = 'moduurnhelp.zendesk.com';
    static zendeskUserField=1500009211882;
    static zendeskUserFormId= 1500002580982;
    
    constructor() { }
}
